<template>
  <div class="order">
    <r-header :title="'详情'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <div class="container">
      <van-cell-group>
        <van-cell>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <div class="flex">
              <van-image
                width="100"
                height="100"
                :src="'/apis' + list.shopimg"
                style="margin-right: 10px;"
              />
              <section>
                <p class="store-name">{{ list.shopname }}</p>
                <p class="order-time margin-t-1" style="margin-top:8px;">电表数：{{ list.dianStart }}</p>
                <p class="order-time">水表数：{{ list.shuiStart }}</p>
              </section>
            </div>
          </template>
        </van-cell>
        <!-- <van-cell icon="shop-o">
          <template #right-icon>
            <p>参考价格  <span class="money">¥<span class="text-m">{{ fmoney(list.shopprice, 2) }}</span></span></p>
          </template>
        </van-cell> -->
        <van-cell :title="`店铺面积：${list.shoparea}平米`" icon="flag-o"></van-cell>
        <van-cell :title="list.address" icon="location-o"></van-cell>
        <!-- <van-cell :title="list.shopdesc" icon="info-o"></van-cell> -->
      </van-cell-group>
      <!-- <van-cell-group style="margin-top:20px">
        <van-cell title="购买须知"></van-cell>
        <van-cell>
          <template #title>
            <div class="">
              <p v-html="yaoqiu"></p>
            </div>
          </template>
        </van-cell>
      </van-cell-group> -->
      <!-- <van-submit-bar :price="Number(totalPrice)" v-click-with-auth-login="onSubmit">
        <template #button>
          <van-button v-if="cancel" round type="default">取消订单</van-button>
          <van-button :disabled="totalPrice === 0" round color="linear-gradient(to right, #ff6034, #ee0a24)" >
            立即购买
          </van-button>
        </template>
      </van-submit-bar> -->
    </div>
    <!-- <van-overlay :show="show" class="overlay" /> -->
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  components: {
    RHeader
  },
  data () {
    return {
      tips: true,
      cancel: false,
      shop: {},
      show: false,
      idArr: [],
      list: [],
      totalPrice: 0,
      id: '',
      batchid: ''
    }
  },
  computed: {
    phone () {
      return this.$store.getters.getUsername
    },
    yaoqiu () {
      return this.list.length > 0 ? this.list[0].yaoqiu : ''
    }
  },
  created () {
    this.idArr = this.$route.query.id.split(',')
    if (!this.$route.query.id) {
      this.backPage()
    } else {
      this.initData()
      console.log(this.list)
    }
  },
  mounted () {
  },
  methods: {
    onSubmit () {
      this.show = true
      if (this.show) {
        const redirect = 'http://www.whwwsc.com/movie/h5pay/'
        const orderId = `1*${this.batchid}`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx63c208bb195251ca&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_userinfo&state=${orderId}#wechat_redirect`
      }
    },
    tipsControl () {
      this.tips = !this.tips
    },
    initData () {
      const arr = this.$route.query.id
      this.$axios.post('/gw/dianpudetail', { id: arr }).then(res => {
        console.log(res.data.data)
        if (res.data.code === 200) {
          const { data } = res.data
          this.list = data
        }
      })
    },
    backPage () {
      this.$router.push({
        name: 'shop'
      })
    }
  }
}
</script>

<style lang="less">
.store-name{
  font-size: 20px;
  font-weight: bold;
}
.tip-text {
  font-size: 12px;
  color: #ee0a24;
}
.order-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background: green;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.store-text{
  font-size: 32px;
  padding: 0;
  margin: 0;
  color: #fff;
}
.order-time{
  font-size: 16px;
  color: rgb(51, 51, 51);
}
.margin-t-2{
  margin-top: 20px;
}
.user-phone{
  font-size: 18px;
}
.order-text{
  color: #666;
}
.phone-img{
  // width:50px;
  // height:50px;
  // background: url(../assets/images/phone.png) no-repeat;
  // background-size: cover;
  // margin-right: 15px;
}
.overlay{
  z-index: 4000!important;
}
</style>
